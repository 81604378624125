body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Custom CSS */

body {
  margin: 0px;
}

.listItems.MuiButtonGroup-vertical{
  display: flex;
  border: 1px solid lightgray;
}

.listItems.MuiButtonGroup-vertical .MuiButtonGroup-groupedText{
  display: flex;
  flex-direction: column;


}

.App .organization{
  text-align: center;
}

.listItems.MuiButtonGroup-vertical .MuiButtonGroup-groupedText .MuiButton-text{

}

.listItems.MuiButtonGroup-vertical .MuiButtonGroup-groupedText .MuiButton-label{
  justify-content: left;
  padding-top:10px;
  padding-bottom:10px;
}

.MuiToolbar-regular {
  min-height: 64px !important;
}


.ButtonGroupTitle{
  border: 1px solid lightgray;
  background-color: lightgray;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  font-weight: 700;
}

.content-body{
  position: absolute;
  top: 64px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}



table.stickycolumn {
  margin-left: -2px;
}
table.stickycolumn td:first-child {
  position: sticky;
  left: 0px;
  background: white;
  z-index: 10;
}

table.stickyheader {
  margin-top: -2px;
  border-spacing: 0px;
}
table.stickyheader th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 11;
}


.MuiListItemText-root {
  width: 100%;
}